<template>
  <div class="notifications">
    <transition-group
      :name="transitionName"
      :mode="transitionMode"
    >
      <notification
        v-for="notification in notifications"
        v-bind="notification"
        :key="notification.timestamp.getTime()"
        :click-handler="notification.onClick"
        @close="removeNotification"
      />
    </transition-group>
  </div>
</template>
<script>
  import Notification from './Notification.vue'

  export default {
    name: 'Notifications',
    components: {
      Notification,
    },
    props: {
      transitionName: {
        type: String,
        default: 'list',
      },
      transitionMode: {
        type: String,
        default: 'in-out',
      },
      overlap: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        notifications: this.$notifications.state,
      }
    },
    watch: {
      overlap: function (newVal) {
        this.$notifications.settings.overlap = newVal
      },
    },
    created () {
      this.$notifications.settings.overlap = this.overlap
    },
    methods: {
      removeNotification (timestamp) {
        this.$notifications.removeNotification(timestamp)
      },
    },
  }
</script>
<style lang="scss">

</style>
