<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="$t('components.businessinfo.title')"
        pace="6"
      >
        {{ $t('components.businessinfo.text') }}
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
    }),
    computed: {
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: this.$t('components.businessinfo.1.title'),
            text: this.$t('components.businessinfo.1.text'),
          },
          {
            icon: 'mdi-email',
            title: this.$t('components.businessinfo.2.title'),
            text: this.$t('components.businessinfo.2.text'),
          },
        ]
      },
    },
  }
</script>
