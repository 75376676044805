import axios from 'axios'
const API_URL = process.env.VUE_APP_API_URL + 'public/'

class EmailService {
  subscribe (email) {
    return axios
      .post(API_URL + 'newsletter/', {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': process.env.VUE_APP_DEFAULT_PKEY,
        },
        email: email,
        channel: 'email',
        from: 'web',
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  sendEmail (email, name, subject, content) {
    return axios
      .post(API_URL + 'sendemail/', {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': process.env.VUE_APP_DEFAULT_PKEY,
        },
        email: email,
        name: name,
        subject: subject,
        content: content,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.error(err)
      })
  }
}

export default new EmailService()
