<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <base-text-field
        v-model="name"
        :rules="[rules.required]"
        :label="$t('components.basecontactform.name')"
      />

      <base-text-field
        v-model="email"
        :rules="[rules.required, rules.email]"
        :label="$t('components.basecontactform.email')"
      />

      <base-text-field
        v-model="subject"
        :rules="[rules.required]"
        :label="$t('components.basecontactform.subject')"
      />

      <base-textarea
        v-model="content"
        :rules="[rules.required, rules.counter]"
        class="mb-6"
        :label="$t('components.basecontactform.description')"
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        outlined
        :disabled="!valid"
        @click.prevent="sendEmail"
      >
        {{ $t('components.basecontactform.cta') }}
      </base-btn>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
    data () {
      return {
        name: '',
        email: '',
        subject: '',
        content: '',
        valid: true,
      }
    },
    computed: {
      rules () {
        return {
          required: value => {
            return !!value || this.$t('common.validation.required')
          },
          counter: value => {
            return value.length <= 300 || this.$t('common.validation.maxchar')
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (pattern.test(value)) {
              this.emailFormat = true
              return true
            } else {
              this.emailFormat = false
              return this.$t('common.validation.email')
            }
          },
        }
      },
    },
    methods: {
      sendEmail () {
        if (this.$refs.form.validate()) {
          this.$store.dispatch('emails/sendEmail', { email: this.email, name: this.name, subject: this.subject, content: this.content }).then(
            (data) => {
              console.log('response', data)
              var message = ''
              var error
              if (data && data.success) {
                message = this.$t('components.basecontactform.emailsent')
                error = false
              } else {
                message = this.$t('components.basecontactform.emailerror')
                error = true
              }
              this.$notify({
                message: message,
                icon: 'mdi mdi-bell',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: error ? 'danger' : 'success',
              })
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            },
          )
        } else {
          // nothing to do here
        }
      },

    },
  }
</script>
