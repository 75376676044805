<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/paydone/check-light.png')"
        contain
        max-width="50"
        min-height="50"
        width="100%"
      />
      <!--
      <base-title
        size="text-body-1"
        space="4"
        title="MULTI-PURPOSE THEME"
        weight="regular"
      /> -->

      <base-body>
        {{ $t('components.info.text') }}
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        to="/about"
      >
        {{ $t('components.info.cta') }}
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Paris Area - Ile de France, France',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'hello@paydone.fr',
        },
      ],
    }),
  }
</script>
