// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/paydone/hilecopter.jpg') },
        },
        // {
        //   path: 'news',
        //   name: 'News',
        //   component: () => import('@/views/news/Index.vue'),
        //   meta: { src: require('@/assets/paydone/private-jet.jpg') },
        // },
        // {
        //   path: 'news/:slug',
        //   name: 'Article',
        //   component: () => import('@/views/news/Article.vue'),
        //   meta: { src: require('@/assets/paydone/private-jet.jpg') },
        // },
        // {
        //   path: 'marketing',
        //   name: 'Marketing',
        //   component: () => import('@/views/marketing/Index.vue'),
        //   meta: { src: require('@/assets/marketing.jpg') },
        // },
        // {
        //   path: 'gallery',
        //   name: 'Gallery',
        //   component: () => import('@/views/gallery/Index.vue'),
        //   meta: { src: require('@/assets/gallery.jpg') },
        // },
        // {
        //   path: 'gallery/:project',
        //   name: 'Project',
        //   component: () => import('@/views/gallery/Project.vue'),
        //   meta: { src: require('@/assets/project.jpg') },
        // },
        {
          path: 'pricing-plans',
          name: 'Pricing',
          component: () => import('@/views/pricing-plans/Index.vue'),
          meta: { src: require('@/assets/paydone/sunset-7.jpg') },
        },
        {
          path: 'contact-us',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/paydone/6-pool.jpg') },
        },
        {
          path: 'devs',
          name: 'Devs',
          component: () => import('@/views/devs/Index.vue'),
          meta: { src: require('@/assets/paydone/6-pool.jpg') },
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/soon/Index.vue'),
          meta: { src: require('@/assets/paydone/6-pool.jpg') },
        },
        {
          path: 'customer',
          name: 'customer',
          component: () => import('@/views/customers/Index.vue'),
          meta: { src: require('@/assets/paydone/6-pool.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
